import { Booker, useEventTypes, useTeamEventTypes, useTeams } from "@calcom/atoms";
import "@calcom/atoms/globals.min.css";
import { CalProvider } from '@calcom/atoms';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import {
  CAL_ATOMS_CLIENT_ID,
  CAL_ATOMS_API_URL,
  CAL_ATOMS_REFRESH_URL,
} from "utils/constants/constants";
import { notify } from "utils/toast/toast";
import { updateAppointment } from "services/updateAppointment";
import { useAxios } from "utils/hooks/useAxios";
  
const BookingReschedule = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [bookingTitle, setBookingTitle] = useState(null)
    let rescheduleUid = params.id
    let eventSlug = new URLSearchParams(location.search).get('slug')
    let calUsername = new URLSearchParams(location.search).get('username')
    const { isLoading: updateAppointmentLoading, callApi: updateAppointmentApi } = useAxios()
    const user = useSelector((state) => state.user)
  
    return (
        <div className="my-4 px-10 py-5">
            <CalProvider
                clientId={CAL_ATOMS_CLIENT_ID}
                options={{
                apiUrl: CAL_ATOMS_API_URL,
                refreshUrl: CAL_ATOMS_REFRESH_URL 
                }}
            >
                <h1 className="my-4 text-2xl font-semibold">Reschedule booking</h1>
                {!bookingTitle && (
                    <Booker 
                        rescheduleUid={rescheduleUid}
                        eventSlug={eventSlug}
                        username={calUsername}
                        defaultFormValues={{
                            name: user.firstName + " " + user.lastName,
                            email: user.email ?? ""
                        }}
                        onCreateBookingSuccess={(data) => {
                            var postData = {
                                appointmentId: data.data.uid,
                            };
                            updateAppointment(updateAppointmentApi, rescheduleUid, postData, (data, error) => {
                                if (data) {
                                  console.log("Appointment update success = ", data);
                                } else if (error) {
                                  console.log("Appointment update error = ", error);
                                }
                            })
                            setBookingTitle(data.data.title)
                        }}
                        onCreateBookingError={(error) => {
                            notify("Unable to book appointment. Please try again later", "error");
                        }}
                    />
                )}
                {bookingTitle && <p> Booking successfully rescheduled: {bookingTitle}</p>}
            </CalProvider>
        </div>
    );
  };

  export default BookingReschedule;