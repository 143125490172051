import {
  CLOSE_CURRENT_MODAL,
  OPEN_FORGOT_PASSWORD_MODAL,
  OPEN_MODAL,
  OPEN_SIGN_IN_MODAL,
  OPEN_SIGN_UP_MODAL,
  OPEN_SIGN_UP_OTP_MODAL,
  OPEN_SUCCESS_MODAL,
  OPEN_VERIFY_EMAIL_MODAL,
} from "../../utils/constants/constants";

const initialState = { currentModal: null, currentTab: null };
export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIGN_IN_MODAL:
      return { ...state, currentModal: OPEN_SIGN_IN_MODAL };
    case OPEN_SIGN_UP_MODAL:
      if (!!action?.payload && !!action?.payload["currentTab"]) {
        return {
          ...state,
          currentModal: action.payload.currentModal,
          currentTab: action.payload.currentTab,
        };
      }
      return { ...state, currentModal: OPEN_SIGN_UP_MODAL };
    case OPEN_SIGN_UP_OTP_MODAL:
      return { ...state, currentModal: OPEN_SIGN_UP_OTP_MODAL };
    case OPEN_VERIFY_EMAIL_MODAL:
      return { ...state, currentModal: OPEN_VERIFY_EMAIL_MODAL };
    case OPEN_FORGOT_PASSWORD_MODAL:
      return { ...state, currentModal: OPEN_FORGOT_PASSWORD_MODAL };
    case OPEN_SUCCESS_MODAL:
      return { ...state, currentModal: OPEN_SUCCESS_MODAL };
    case OPEN_MODAL:
      return { ...state, currentModal: action.payload };
    case CLOSE_CURRENT_MODAL:
      return initialState;
    default:
      return state;
  }
};
