import React from "react";
import logo from "assets/logo_big.png";
import { useSelector } from "react-redux";
import { ADVANCED_SEARCH } from "utils/constants/constants";

const SearchLayout = ({ children, searchType, setSearchType }) => {
  const userType = useSelector((state) => state.user?.userType);
  return (
    <div className="flex flex-col items-center mt-[7.5rem] pb-20">
      <img src={logo} alt="Lawyer Up Logo" />
      <p className="text-[1.625rem] mt-1 text-textColor">
        We're Modernizing Legal.
      </p>
      {userType !== "PROVIDER" && (
        <>
          {children}
          <div className="mt-[1.188rem] text-secondary text-center font-[400] ">
            {searchType === ADVANCED_SEARCH
              ? "Want to describe your legal issue?"
              : "Know what type of lawyer you are looking for?"}
            <button
              onClick={setSearchType}
              className="text-primary hover:text-[#0099CB] font-[700]] pl-2 hover:underline"
            >
              Click Here
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchLayout;
