import React from "react";
import { useDispatch, useSelector } from "react-redux";
import backgroundImage from "../assets/background.png";
import overlay from "../assets/overlay.png";
import { openSignUpModal } from "../store/actions/modalActions";
import { CURRENT_SIGNUP_TAB } from "utils/constants/constants";

const HelpBanner = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  return (
    <div
      className="h-max help bg-cover bg-right mb-[7rem]"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className="h-full help1 bg-cover "
        style={{ backgroundImage: `url(${overlay})` }}
      >
        <div className="w-[47%] banner p-[5rem] py-[7rem]">
          <h1 className="text-[2.875rem] text-secondary leading-tight font-bold ">
            Lawyers – Join the New Legal Paradigm!
          </h1>
          <p className="text-[1.25rem] helpp mt-3 text[20px] font-semibold md:text-[15px] text-[#5A5A5A]">
            Leverage the power of artificial intelligence to match with clients
            looking for your specific experience.
          </p>
          {!user && (
            <button
              className="bg-primary mt-6 py-3 px-6 rounded-sm text-white w-[183px] h-[56px] hover:bg-[#0099CB] duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
              onClick={() => dispatch(openSignUpModal(CURRENT_SIGNUP_TAB))}
            >
              Sign Up Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpBanner;
