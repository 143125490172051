import React from "react";

const HomeCard = ({ data }) => {
  return (
    <div
      className={` cards-width w-[305px] h-[284px] sm:w-[285px] card_desk card_mb sm:h-[275px] md:w-[285px] p-4 md:p-4 flex flex-col  items-start ${
        data.active ? "bg-secondary" : "bg-gray"
      } rounded-[10px] ${
        data.active
          ? "text-white  shadow-[#29292a1a] shadow-lg"
          : "text-secondary"
      }`}
    >
      <img
        className="mt-4 w-[32.25px] h-[35.83px] ml-3 object-contain"
        src={data.icon}
        alt="Icon"
      />
      <p className="font-[500] text-[12px] md:text-[16px] leading-[32px] pt-3 pb-1.5 tracking-[0.1px] ml-3">
        {data.step}
      </p>
      <h1 className="font-[500] md:font-[700] text-[14px] md:text-[20px] ml-3 leading-[20px] md:leading-[26px] tracking-[0.1px]">
        {data.heading}
      </h1>
      <p
        className={` hidden md:block  font[400] text-[16px] pt-3 text-[400] ml-3 ${
          data.active ? "bg-secondary" : "bg-gray"
        } rounded-[10px] ${data.active ? "text-white  " : "text-textColor"}`}
      >
        {data.para}
      </p>
    </div>
  );
};

export default HomeCard;
