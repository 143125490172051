import React, { useLayoutEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  emailVerifiedSuccessfully,
  signInAction,
} from "store/actions/authActions";
import {
  closeCurrentModal,
  openModal,
  openSignUpOtpModal,
  openVerifyEmailModal,
} from "store/actions/modalActions";
import { routes } from "utils/appConfig";
import {
  OPEN_PAYMENT_MODAL,
  OPEN_FORGOT_PASSWORD_MODAL,
  OPEN_FORGOT_PASSWORD_OTP_MODAL,
  OPEN_RESET_PASSWORD_MODAL,
  OPEN_RESET_PASSWORD_SUCCESSFUL_MODAL,
  OPEN_SIGN_IN_MODAL,
  OPEN_SIGN_UP_MODAL,
  OPEN_SIGN_UP_OTP_MODAL,
  OPEN_SUBSCRIPTION_MODAL,
  OPEN_SUCCESS_MODAL,
  OPEN_VERIFY_EMAIL_MODAL,
  STRIPE_PUBLISHABLE_KEY,
  EXPERIENCE_MODAL,
  STRIPE_MODAL,
  DATE_RANGE_MODAL,
  PROVIDER_CONTACT_MODAL,
  PLANS_MODAL,
  FILTER_MODAL,
  PAYMENT_SUCCESS_MODAL,
  ADD_CREDIT_CARD_MODAL,
  DELETE_CARD_SUCCESS_MODAL,
  ADD_CARD_SUCCESS_MODAL,
  SELECT_CARD_MODAL,
  BL_RF_TOKEN,
  STANDARD_PACKAGE,
  OPEN_BOOKING_APPOINTMENT_MODAL,
} from "utils/constants/constants";
import { useAxios } from "utils/hooks/useAxios";
import ForgotPasswordModal from "components/modals/authModal/ForgotPasswordModal";
import OTPModal from "../authModal/OTPModal";
import ResetPasswordModal from "components/modals/authModal/ResetPasswordModal";
import SignInModal from "components/modals/authModal/SignInModal";
import SignUpModal from "components/modals/authModal/SignUpModal";
import SuccessModal from "components/modals/authModal/SuccessModal";
import ModalProvider from "components/modals/modalProvider/ModalProvider";
import SubscriptionModal from "components/modals/subscriptionModal/SubscriptionModal";
import PaymentModal from "components/modals/subscriptionModal/PaymentModal";
import { useLocation, useNavigate } from "react-router-dom";
import ExperienceModal from "components/modals/subscriptionModal/ExperienceModal";
import { notify } from "utils/toast/toast";
import StripeModal from "components/modals/subscriptionModal/StripeModal";
import DateRangeModal from "components/modals/dateRangeModal/DateRangeModal";
import ProviderContactModal from "components/modals/ProviderContactModal/ProviderContactModal";
import PlansModal from "components/modals/PlansModal/PlansModal";
import FilterModal from "components/modals/filterModal/FilterModal";
import SelectCardModal from "components/modals/subscriptionModal/SelectCardModal";
import useCookies from "utils/hooks/useCookie";
import { calculateDiscountedPrice } from "utils/helperFunctions/helper";
import { fetchLoggedInUserInfo } from "services/fetchUserInfo";
import BookAppointmentModal from "components/modals/CalAtomsModal/BookingAppointmentModal";

const MountModals = () => {
  const location = useLocation();
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, callApi } = useAxios();
  const user = useSelector((state) => state.user);
  const modal = useSelector((state) => state.modal);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [couponStatus, setCouponStatus] = useState({});
  const [subscriptionDetail, setSubscriptionDetail] = useState({
    productId: STANDARD_PACKAGE,
  });

  const { setCookie } = useCookies();

  const token = user?.accessToken;
  const {
    signIn,
    signUp,
    emailVerification,
    otpVerification,
    forgotPassword,
    me,
    resetPassword,
  } = routes;
  useLayoutEffect(() => {
    if (user && !user?.isEmailVerified && !modal.currentModal) {
      dispatch(openVerifyEmailModal());
    } else if (
      modal.currentModal === OPEN_VERIFY_EMAIL_MODAL &&
      user?.isEmailVerified
    ) {
      dispatch(closeCurrentModal());
    }
  }, [user]);
  const onSignInFormSubmit = async (values) => {
    const { url, method } = signIn;
    await callApi(url, method, values, {
      onSuccess: async ({ data }) => {
        let user = { ...data.data.user, accessToken: data.data.accessToken };
        if (data?.data?.accessToken) {
          setCookie(BL_RF_TOKEN, data.data.refreshToken);
          await callApi(
            me.url,
            me.method,
            {},
            {
              onSuccess: ({ data }) => {
                user = { ...user, ...data.data };
              },
              headers: {
                Authorization: `Bearer ${data.data.accessToken}`,
              },
              turnOffNotification: true,
            }
          );
        }
        dispatch(signInAction(user));
        dispatch(closeCurrentModal());
        if (user?.isEmailVerified) {
          const userHaveNotSubscription = !user?.subscription && user?.userType === "PROVIDER";
          if (
            user.userType === "PROVIDER" &&
            (user.stepsCompleted < 6 || !user.stepsCompleted)
          ) {
            navigate("/completeprofile");
          } else if (userHaveNotSubscription && user.stepsCompleted === 6) {
            dispatch(openModal(OPEN_SUBSCRIPTION_MODAL));
          } else if (
            user.userType === "PROVIDER" &&
            location?.pathname === "/searchresult"
          ) {
            navigate("/");
            notify("Providers not allowed to search!", "warn");
          }
        }
      },
    });
  };

  const onSignUpFormSubmit = async (values) => {
    const { url, method } = signUp;
    await callApi(url, method, values, {
      onSuccess: ({ data }) => {
        dispatch(openSignUpOtpModal());
        dispatch(signInAction(data.data));
      },
    });
  };

  const onOtpSubmit = async (verificationToken) => {
    const email =
      modal?.currentModal === OPEN_SIGN_UP_OTP_MODAL
        ? user?.email
        : localStorage?.getItem("email");
    const source =
      modal?.currentModal === OPEN_SIGN_UP_OTP_MODAL
        ? emailVerification.source
        : forgotPassword.source;
    const data = {
      source,
      email,
      verificationToken: Number(verificationToken),
    };
    await callApi(otpVerification.url, otpVerification.method, data, {
      onSuccess: async ({ data }) => {
        if (modal?.currentModal === OPEN_SIGN_UP_OTP_MODAL) {
          if (user?.userType === "PROVIDER") {
            dispatch(signInAction(data.data));
            // Navigate user to complete profile after OTP verification is complete
            dispatch(openModal(OPEN_SUCCESS_MODAL));
            navigate("/profile");
          } else {
            dispatch(openModal(OPEN_SUCCESS_MODAL));
            dispatch(emailVerifiedSuccessfully(data.data.accessToken));
          }
          setTimeout(() => {
            //Auto-close sign up success modal after 3s
            dispatch(closeCurrentModal())
          }, 3000);
        } else {
          localStorage.removeItem("email");
          localStorage.setItem("token", data.data.accessToken);
          dispatch(openModal(OPEN_RESET_PASSWORD_MODAL));
          // dispatch(signInAction(data.data));
          // await callApi(
          //   me.url,
          //   me.method,
          //   {},
          //   {
          //     headers: {
          //       Authorization: `Bearer ${data.data.accessToken}`,
          //     },
          //     onSuccess: ({ data }) => {
          //       dispatch(signInAction(data.data));
          //       dispatch(openModal(OPEN_RESET_PASSWORD_MODAL));
          //     },
          //     turnOffNotification: true,
          //   }
          // );
        }
      },
    });
  };
  const onVerifyEmailSubmitModal = async (email) => {
    await callApi(
      emailVerification.url,
      emailVerification.method,
      { email, source: emailVerification.source },
      {
        onSuccess: () => {
          dispatch(openSignUpOtpModal());
        },
        onError: ({ response }) => {
          const { message } = response?.data || {};
          if (message === "Your email is already verified") {
            dispatch(closeCurrentModal());
            fetchLoggedInUserInfo(callApi, ({ data }) => {
              dispatch(signInAction(data));
            });
          }
        },
      }
    );
  };
  const onForgotPasswordSubmit = async (email) => {
    await callApi(
      forgotPassword.url,
      forgotPassword.method,
      { email, source: forgotPassword.source },
      {
        onSuccess: async () => {
          localStorage.setItem("email", email);
          dispatch(openModal(OPEN_FORGOT_PASSWORD_OTP_MODAL));
        },
      }
    );
  };

  const onResetPasswordSubmit = async (password) => {
    const { url, method } = routes.user;
    const token = localStorage.getItem("token");
    await callApi(
      url,
      method,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onSuccess: () => {
          dispatch(openModal(OPEN_RESET_PASSWORD_SUCCESSFUL_MODAL));
          localStorage.removeItem("token");
        },
      }
    );
  };
  const onSubscriptionSubmit = async (subscriptionType) => {
    setSelectedSubscription(subscriptionType);
    dispatch(openModal(OPEN_PAYMENT_MODAL));
  };
  const onPaymentModalSubmit = async () => {
    let updatedUser = {};
    await callApi(
      me.url,
      me.method,
      {},
      {
        onSuccess: ({ data }) => {
          updatedUser = { ...user, ...data.data };
        },
        turnOffNotification: true,
      }
    );
    dispatch(signInAction(updatedUser));
    if (user?.stepsCompleted < 5 || !user?.stepsCompleted) {
      navigate("/completeprofile");
    }
    dispatch(openModal(PAYMENT_SUCCESS_MODAL));
  };
  return (
    <ModalProvider>
      {modal?.currentModal === OPEN_SIGN_IN_MODAL && (
        <SignInModal onSubmit={onSignInFormSubmit} loader={isLoading} />
      )}
      {modal?.currentModal === OPEN_SIGN_UP_MODAL && (
        <SignUpModal onSubmit={onSignUpFormSubmit} loader={isLoading} />
      )}
      {modal?.currentModal === OPEN_SIGN_UP_OTP_MODAL && (
        <OTPModal onSubmit={onOtpSubmit} loader={isLoading} />
      )}
      {modal?.currentModal === OPEN_SUBSCRIPTION_MODAL && (
        <SubscriptionModal
          onSubmit={onSubscriptionSubmit}
          loader={isLoading}
          setSubscriptionDetail={setSubscriptionDetail}
          setPromoCode={setPromoCode}
          promoCode={promoCode}
          couponStatus={couponStatus}
          setCouponStatus={setCouponStatus}
          subscriptionDetail={subscriptionDetail}
        />
      )}
      {modal?.currentModal === OPEN_PAYMENT_MODAL && (
        <Elements stripe={stripePromise}>
          <PaymentModal
            onSubmit={onPaymentModalSubmit}
            selectedSubscription={selectedSubscription}
            loader={isLoading}
            promoCode={promoCode}
            couponStatus={couponStatus}
          />
        </Elements>
      )}
      {modal?.currentModal === ADD_CREDIT_CARD_MODAL && (
        <Elements stripe={stripePromise}>
          <PaymentModal addCard />
        </Elements>
      )}
      {modal?.currentModal === OPEN_SUCCESS_MODAL && (
        <SuccessModal
          label="Signed Up Successfully!"
          loader={isLoading}
          // description="Please login to complete your profile."
          // buttonText="Back to Login"
          onSubmit={() => dispatch(openModal(OPEN_SIGN_IN_MODAL))}
        />
      )}
      {modal?.currentModal === PAYMENT_SUCCESS_MODAL && (
        <SuccessModal
          label="Payment Made Successfully!"
          loader={isLoading}
          description={`You’ve paid ${calculateDiscountedPrice(
            subscriptionDetail?.productPrice,
            couponStatus.percent_off,
            true
          )}/y for ${subscriptionDetail?.productName}`}
          buttonText="View Profile"
          onSubmit={() => {
            setCouponStatus({});
            dispatch(closeCurrentModal());
          }}
          onClose={() => {
            setCouponStatus({});
            dispatch(closeCurrentModal());
          }}
        />
      )}
      {/* Verify email modal as a fallback */}
      {modal?.currentModal === OPEN_VERIFY_EMAIL_MODAL && (
        <ForgotPasswordModal
          onSubmit={onVerifyEmailSubmitModal}
          loader={isLoading}
        />
      )}
      {modal?.currentModal === OPEN_FORGOT_PASSWORD_MODAL && (
        <ForgotPasswordModal
          onSubmit={onForgotPasswordSubmit}
          loader={isLoading}
        />
      )}
      {modal?.currentModal === OPEN_FORGOT_PASSWORD_OTP_MODAL && (
        <OTPModal onSubmit={onOtpSubmit} loader={isLoading} />
      )}
      {modal?.currentModal === OPEN_RESET_PASSWORD_MODAL && (
        <ResetPasswordModal
          onSubmit={onResetPasswordSubmit}
          loader={isLoading}
          onClose={() => {
            dispatch(closeCurrentModal());
            if (location?.pathname === "/searchresult") {
              navigate("/");
            }
          }}
        />
      )}
      {modal?.currentModal === OPEN_RESET_PASSWORD_SUCCESSFUL_MODAL && (
        <SuccessModal
          label="Password Updated!"
          description="Your password has been reset successfully."
          loader={isLoading}
        />
      )}
      {modal?.currentModal === EXPERIENCE_MODAL && (
        <ExperienceModal
          label="Password Updated!"
          description="Your password has been reset successfully."
        />
      )}
      {modal?.currentModal === STRIPE_MODAL && <StripeModal />}
      {modal?.currentModal === DATE_RANGE_MODAL && <DateRangeModal />}
      {modal?.currentModal === PROVIDER_CONTACT_MODAL && (
        <ProviderContactModal />
      )}
      {modal?.currentModal === PLANS_MODAL && <PlansModal />}
      {modal?.currentModal === FILTER_MODAL && <FilterModal />}
      {modal?.currentModal === SELECT_CARD_MODAL && (
        <SelectCardModal setSubscriptionDetail={setSubscriptionDetail} />
      )}
      {modal?.currentModal === DELETE_CARD_SUCCESS_MODAL && (
        <SuccessModal
          label="Card Deleted Successfully !"
          buttonText="Goto Back"
          onSubmit={() => dispatch(closeCurrentModal())}
        />
      )}
      {modal?.currentModal === ADD_CARD_SUCCESS_MODAL && (
        <SuccessModal
          label="Card Added Successfully !"
          buttonText="Go Back"
          onSubmit={() => dispatch(closeCurrentModal())}
        />
      )}
      {modal?.currentModal === OPEN_BOOKING_APPOINTMENT_MODAL && !!location.state && (
        <BookAppointmentModal
          location={location}
        />
      )}
    </ModalProvider>
  );
};

export default MountModals;
