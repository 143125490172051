import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import ExternalLink from "components/ExternalLink";

const About = () => {
  return (
    <Container maxWidth="md">
      <Stack justifyContent="center" width="100%">
        <Typography variant="h3" align="center" fontWeight={600}>
          About
        </Typography>
        <br />
        <Typography variant="h6">
          Brown University lists{" "}
          <ExternalLink
            target="_blank"
            href="https://www.brown.edu/academics/college/advising/law-school/fields-law/fields-law"
            rel="noreferrer"
          >
            17 major areas of law practice
          </ExternalLink>
          . LegalJobs claims there are{" "}
          <ExternalLink
            target="_blank"
            href="https://legaljobs.io/blog/types-of-lawyers/"
            rel="noreferrer"
          >
            28 different types of lawyers
          </ExternalLink>
          . The U.S. Department of Justice lists{" "}
          <ExternalLink
            href="https://www.justice.gov/legal-careers/justice-practice-areas"
            target="_blank"
            rel="noreferrer"
          >
            86 distinct legal practice areas
          </ExternalLink>
          . In our research, we have identified over 150 unique legal
          specialties!
        </Typography>
        <br />
        <Typography variant="h6" align="justify">
          We created{" "}
          <ExternalLink
            href="https://juricon.ai/"
            target="_blank"
            rel="noreferrer"
          >
            JuriCon.ai
          </ExternalLink>{" "}
          to solve one problem – how do you connect lawyers, and their
          specialized expertise, with clients looking for a specific capability?
          Traditional marketing avenues – radio, television, billboards – are
          ineffective in matching practitioners of over 150 legal specialties
          with people and businesses looking for their expertise. The internet,
          while overflowing with data, is only effective if you know what you
          are looking for. With so many legal specializations, how do potential
          clients even know what type of lawyer they need to begin their search?
        </Typography>
        <br />
        <Typography variant="h6" align="justify">
          Enter{" "}
          <ExternalLink
            href="https://juricon.ai/"
            target="_blank"
            rel="noreferrer"
          >
            JuriCon.ai
          </ExternalLink>
          . Using proprietary artificial intelligence, potential clients are
          able to describe their legal issues in plain language and be matched
          with the lawyers who have the right training and experience to solve
          their legal issue. Lawyers are able to market their unique experience
          and expertise to clients they can truly help.
        </Typography>
        <br />
        <Typography variant="h6" align="justify">
          <ExternalLink
            href="https://juricon.ai/"
            target="_blank"
            rel="noreferrer"
          >
            JuriCon.ai
          </ExternalLink>{" "}
          is modernizing legal markets. Give it a try!
        </Typography>
        <br />
        <Typography variant="h6" align="justify">
          – Ryan Newburn, Founder
        </Typography>
        <br />
      </Stack>
    </Container>
  );
};

export default About;
