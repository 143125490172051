import React, { useEffect, useState } from "react";
import { bookmarkDeleteApi, bookmarkPostApi } from "services/bookmark";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/actions/modalActions";
import { OPEN_SIGN_IN_MODAL } from "utils/constants/constants";

const BookmarkButton = ({
  searchId,
  providerId,
  loading,
  bookmarkedLawyers,
  className,
}) => {
  const [isBookmarked, setIsBookmarked] = useState(
    !!bookmarkedLawyers?.includes(providerId)
  );
  const [isLoading, setIsLoading] = useState(!!loading);
  const { callApi } = useAxios();
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const handleBookmarkClick = (e) => {
    if (user === null) {
      dispatch(openModal(OPEN_SIGN_IN_MODAL));
    } else {
      setIsLoading(true);
      e.stopPropagation();
      if (isBookmarked) {
        bookmarkDeleteApi(callApi, searchId, providerId, () => {
          setIsBookmarked(false);
          setIsLoading(false);
        });
      } else {
        console.log("bookmarkPostApi: searchId, providerId", searchId, providerId);

        bookmarkPostApi(callApi, searchId, providerId, () => {
          setIsBookmarked(true);
          setIsLoading(false);
        });
      }
    }
  };
  return (
    <div
      onClick={handleBookmarkClick}
      className={`w-10 h-10 bg-gray rounded-[6px] justify-center flex cursor-pointer ${className}`}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <i
          className={`fa-bookmark mt-3 ${
            isBookmarked ? "fa text-primary" : "fa-regular text-[#5A5A5A4D]"
          } `}
        ></i>
      )}
    </div>
  );
};

export default BookmarkButton;
