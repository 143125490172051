import React, { useCallback, useEffect, useRef, useState } from "react";
import logo from "assets/logo_small.png";
import logoWithoutText from "assets/logo_without_text.png";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useClickOutside from "utils/hooks/useClickOutside";
import { openSignInModal } from "store/actions/modalActions";
import NavDropDown from "components/navbar/NavDropDown";
import NavSearch from "./NavSearch";
import Button from "components/button/Button";
import { routes } from "utils/appConfig";
import { useAxios } from "utils/hooks/useAxios";
import {
  addCategories,
  addStates,
  updateBusinessList,
  updateCategoriesReducer,
} from "store/actions/actions";
import {
  ADD_LANGUAGES,
  ADD_NAIC_CODES,
  ADD_SCHOOLS_DATA,
} from "utils/constants/constants";
import { fetchBusinessList } from "services/business";
import { fetchNAICCodes } from "services/fetchNAICCodes";
import { hideIncompleteFeatures } from "utils/config";
import { sortData } from "utils/helperFunctions/helper";
import { fetchSchoolsList } from "services/fetchSchoolsList";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const authenticated = useSelector((state) => state?.user?.accessToken);
  const userType = useSelector((state) => state?.user?.userType);
  const states = useSelector((state) => state?.categoriesReducer.states);
  const languages = useSelector((state) => state?.categoriesReducer.languages);
  // const naicCodes = useSelector((state) => state?.categoriesReducer.naicCodes);
  const businesses = useSelector((state) => state?.businessReducer.businesses);
  const categories = useSelector(
    (state) => state?.categoriesReducer.categories
  );
  const businessList = useSelector((state) => state.user?.myBusinesses);
  const firstName = useSelector((state) => state?.user?.firstName);
  const profilePicture = useSelector((state) => state?.user?.profilePicture);
  const dispatch = useDispatch();
  const showMenuRef = useRef(null);
  useClickOutside([showMenuRef], () => setShowMenu(false));
  const location = useLocation();
  const { callApi } = useAxios();

  useEffect(() => {
    if (authenticated && userType === "PROVIDER") {
      fetchBusinessList(callApi, (data) => {
        if (data) {
          dispatch(updateBusinessList(data?.data));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (authenticated && userType === "PROVIDER") {
      //wrapped in if conditions if data is stored in browser then no need to call api
      if (!businesses?.length) {
        fetchBusinessList(callApi, (data) => {
          if (data) {
            dispatch(updateBusinessList(data?.data));
          }
        });
      }
      // if (!naicCodes?.length) {
      //   fetchNAICCodes(callApi, (data) => {
      //     if (data) {
      //       dispatch(updateCategoriesReducer(ADD_NAIC_CODES, data.data));
      //     }
      //   });
      // }
    }
    if (!languages?.length) {
      callApi(
        routes.languages.url,
        routes.languages.method,
        {},
        {
          onSuccess: ({ data }) => {
            dispatch(updateCategoriesReducer(ADD_LANGUAGES, data.data));
          },
          turnOffNotification: true,
        }
      );
    }
    if (!categories?.length) {
      const { url, method } = routes.categories;
      callApi(
        url,
        method,
        {},
        {
          headers: {
            Authorization: null,
          },
          onSuccess: ({ data }) => {
            const categories = sortData([...data.data], "asc", "name");
            dispatch(addCategories(categories));
          },
          turnOffNotification: true,
        }
      );
    }
    if (!states?.length) {
      const { url, method } = routes.states;
      callApi(
        url,
        method,
        {},
        {
          onSuccess: ({ data }) => {
            const sortedStates = sortData(data?.data, "asc", "name");
            dispatch(addStates(sortedStates));
          },
          turnOffNotification: true,
        }
      );
    }
  }, [authenticated]);

  const NavbarLogo = useCallback(() => {
    return (
      <div>
        <div className="hidden md:block">
          <Link to="/">
            <img
              src={logo}
              alt="Lawyer Up Logo"
              className="pt-2 self-start min-w-[200px] min-h-[54px] max-w-[200px] max-h-[54px]"
            />
          </Link>
        </div>
        <div className="md:hidden mr-2 -mt-2">
          <Link to="/">
            <img
              src={logoWithoutText}
              alt="Lawyer Up Logo"
              className="pt-2 self-start max-w-[100px] max-h-[54px]"
            />
          </Link>
        </div>
      </div>
    );
  }, [logo, logoWithoutText]);

  return (
    <div className="fixed w-[99vw] top-0 bg-[#ffffff] z-40 pr-2 md:pr-0">
      <div className="flex flex-col justify-center max-w-[94%] m-auto  pb-3">
        <div className="flex pt-4 flex-wrap">
          {location?.pathname === "/" || location?.pathname === "/admin" ? (
            <div></div>
          ) : (
            NavbarLogo()
          )}
          <div className="nav-content flex flex-1 items-start justify-between">
            {(location?.pathname != "/" && userType != "PROVIDER") ? (
              <NavSearch />
            ) : (
              <>
                {" "}
                <div></div>
                <div></div>
              </>
            )}
            {authenticated ? (
              <div
                ref={showMenuRef}
                className="relative flex pt-2 -ml-4 items-center"
              >
                <div>
                  {!hideIncompleteFeatures && (
                    <i className="fa-regular fa-bell text-[#5A5A5A99] text-2xl pr-6"></i>
                  )}
                </div>
                <div
                  className="flex items-center hover:cursor-pointer"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  {profilePicture ? (
                    <img
                      src={profilePicture}
                      className="rounded-md w-[40px] h-[40px] object-contain"
                      alt={firstName}
                    />
                  ) : (
                    <i className="fa-solid fa-user text-borderColor fa-xl"></i>
                  )}
                  <i className="fa-solid fa-chevron-down text-[#5A5A5A] text-lg pl-1"></i>
                </div>
                {showMenu && (
                  <NavDropDown
                    name={firstName}
                    setShowMenu={setShowMenu}
                    businessList={businessList ?? []}
                  />
                )}
              </div>
            ) : (
              <div className="min-w-[100px] flex items-center">
                <Button
                  className="bg-primary mt-1 py-2 px-4 rounded-xs text-white h-[41px] hover:bg-[#0099CB] duration-300	 focus:outline-none focus:ring-2 focus:[primary] focus:ring-opacity-75 w-[100%] text-center"
                  onClick={() => dispatch(openSignInModal())}
                >
                  Sign In
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
