import AdvancedSearch from "components/search/AdvancedSearch";
import GeneralSearch from "components/search/GeneralSearch";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetSearchReducer, updateSearchQuery } from "store/actions/actions";
import { hideIncompleteFeatures } from "utils/config";
import { ADVANCED_SEARCH, GENERAL_SEARCH } from "utils/constants/constants";

export const handleWhatYouAreLookingClick = (dispatch, searchType) => {
  dispatch(resetSearchReducer());
  dispatch(
    updateSearchQuery({
      searchType:
        searchType === ADVANCED_SEARCH ? GENERAL_SEARCH : ADVANCED_SEARCH,
    })
  );
};

const NavSearch = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { searchType } = useSelector((state) => state.searchReducer);

  return (
    <div className="flex-grow">
      <div className=" flex justify-center items-center w-[100%] pr-10  ">
        {searchType === ADVANCED_SEARCH ? (
          <AdvancedSearch navSearch />
        ) : (
          <GeneralSearch navSearch />
        )}
      </div>
      <div className="flex flex-col justify-center lg:flex-row lg:justify-evenly mt-4 w-[100%]">
        <div className="flex justify-center">
          <label className="block mb-6 text-xs sm:text-[14px] font-medium  mt-[2px] text-textColor text-center w-[80%] md:w-[100%]">
            {searchType === ADVANCED_SEARCH
              ? "Want to describe your legal issue?"
              : "Know what type of lawyer you are looking for?"}
          </label>
          <div className="text-xs sm:text-sm font-medium text-textColor min-w-fit w-[50%] md:w-auto">
            <span
              onClick={() => handleWhatYouAreLookingClick(dispatch, searchType)}
              className="hover:underline dark:text-blue-500 text-center text-primary pl-2 cursor-pointer"
            >
              Click Here
            </span>
          </div>
        </div>
        {!hideIncompleteFeatures &&
          location.pathname === "/searchresult" &&
          searchType === ADVANCED_SEARCH && (
            <div className="flex justify-center">
              <label className="block mb-6 text-xs sm:text-[14px] font-medium  text-textColor">
                Help us to Improve Search Results!
              </label>
              <div className="text-xs sm:text-sm font-medium text-textColor">
                <button className="hover:underline dark:text-blue-500 text-center text-primary pl-2">
                  Click Here
                </button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default NavSearch;
