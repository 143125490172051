import pageIcon from "../assets/page.svg";
import smartIcon from "../assets/smart.svg";
import shieldIcon from "../assets/shield.svg";
import calenderIcon from "../assets/calendar.svg";

const cardData = [
  {
    icon: pageIcon,
    step: "Step 1",
    heading: "Tell us in your own words",
    para: "Describe your legal needs using plain language.",
    active: true,
  },
  {
    icon: smartIcon,
    step: "Step 2",
    heading: "Smart Search",
    para: "Our AI will craft a list of lawyers able to meet your legal needs.",
    active: false,
  },
  {
    icon: shieldIcon,
    step: "Step 3",
    heading: "Free Sign Up",
    para: "Sign up for a FREE account to review your customized lawyer matches.",
    active: false,
  },
  {
    icon: calenderIcon,
    step: "Step 4",
    heading: "Schedule Meeting",
    para: "Schedule meetings with your selected lawyers directly from your account.",
    active: false,
  },
];

export default cardData;
