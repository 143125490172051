import React from "react";
import cardData from "../data/CardData";
import HomeCard from "./cards/homeCard/HomeCard";

const Cards = () => {
  return (
    <div className="flex justify-center">
      <div className="w-[95%] self-center grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2  place-items-center gap-y-3 my-28">
        {cardData.map((item, i) => {
          return <HomeCard data={item} key={i} />;
        })}
      </div>
    </div>
  );
};

export default Cards;
