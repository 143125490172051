import Error from "components/error/Error";
import { alphaNumericValidation } from "components/modals/helper";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSearchQuery } from "store/actions/actions";
import { GENERAL_SEARCH } from "utils/constants/constants";
import * as Yup from "yup";

const GeneralSearch = ({ navSearch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => state.searchReducer);
  useEffect(() => {
    formik.setValues({
      searchText,
    });
  }, [searchText]);
  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    // validationSchema: Yup.object().shape({
    //   searchText: alphaNumericValidation("Search").required().label("Search"),
    // }),
    onSubmit: (value) => {
      if (value?.searchText) {
        dispatch(
          updateSearchQuery({
            searchText: value.searchText,
            searchType: GENERAL_SEARCH,
          })
        );
        navigate("/searchresult");
      }
    },
  });
  const { getFieldProps } = formik;
  return (
    <div
      className={`${!navSearch && "my-6"} w-[${
        !navSearch ? "70%" : "100%"
      }] flex flex-col justify-center items-center`}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="flex items-center border-2 border-[#c6c7c481] w-[100%] md:w-[75.5%] h-[50px] sm:mt-[10px] rounded-sm"
      >
        <input
          type="text"
          name="searchText"
          {...getFieldProps("searchText")}
          className=" h-[2rem] w-[92%] outline-none px-5 text-lg font-semibold text-secondary placeholder:text-[#c6c7c4c4] border-r-2 border-[#c6c7c481]"
          placeholder="Describe your legal issue"
        />
        <div className="ml-[5px] w-[45px] md:w-[73px]  text-center flex justify-center ">
          <button type="submit" onClick={formik.handleSubmit}>
            <i className="fa-solid fa-magnifying-glass text-3xl text-[#5c5e5eb2] hover:text-primary transition-all cursor-pointer"></i>
          </button>
        </div>
      </form>
      <Error formik={formik} errorField="searchText" />
    </div>
  );
};

export default GeneralSearch;
