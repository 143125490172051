import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchResults from "./pages/SearchResults";
import Home from "./pages/Home";
import ErrorPage from "./pages/ErrorPage";
import EditProfile from "pages/EditProfile";
import MountModals from "./components/modals/mountModals/MountModals";
import { ToastContainer } from "react-toastify";
import IndividualConsumerProfile from "pages/IndividualConsumerProfile";
import CompleteProfileMain from "pages/others/CompleteProfileMain";
import ProtectedRoute from "components/protectedRoute/ProtectedRoute";
import Navbar from "components/navbar/Navbar";
import Footer from "components/Footer";
import ProviderDetails from "components/PremiumProviderEditProfileComponents/providermui/ProviderDetail";
import BusinessDetail from "components/Business/businessmui/BusinessDetail";

import DashBoard from "components/Dashboard/DashBoard";
import ErrorBoundary from "pages/ErrorBoundary";
import Membership from "pages/Membership";
import CreateFirm from "pages/CreateFirm";
import Firm from "pages/Firm";
import ProfileNewScope from "pages/ProfileNewScope";
import ConsumerMatterDetails from "pages/ConsumerMatterDetails";
import ProviderDetailsNewScope from "components/providerDetailNewScope/ProviderDetailsNewScope";
import "@fortawesome/fontawesome-free/css/all.css";
import Juricon from "pages/Juricon";
import AdminPanel from "pages/AdminPanel";
import AdminUserUpdate from "pages/AdminUserUpdate";
import Settings from "pages/Settings";
import InquiryDetails from "components/inquiry/InquiryDetails";
import About from "pages/About";
import TermsOfService from "pages/TermsOfService";
import Invoices from "pages/Invoices";
import ReactGA from "react-ga4";
import { TRACKING_ID } from "utils/config";
import TagManager from 'react-gtm-module'
import ManageCalendar from "pages/ManageCalendar";
import BookingReschedule from "pages/BookingReschedule";
import BookingManagerMain from "pages/BookingManagerMain";
import BookingDetails from "pages/BookingDetails";
import BookingCancel from "pages/BookingCancel";
import BookingDetailsDirectLink from "pages/BookingDetailsDirectLink"

ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs);

const App = () => {
  const isJuriconRoute = window.location.pathname.includes("/juricon");
  return (
    <div>
      <BrowserRouter>
        <ErrorBoundary>
          <ToastContainer />
          <div
            className={
              !isJuriconRoute && "w-[100%] m-auto pt-[6rem] flex flex-col"
            }
          >
            {isJuriconRoute ? null : <Navbar />}
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route exact path="/profile/edit" element={<EditProfile />} />
                <Route
                  path="/completeprofile"
                  element={<CompleteProfileMain />}
                />
                <Route path="/dashboard" element={<DashBoard />} />
                <Route path="/profile" element={<ProfileNewScope />} />
                <Route path="/createfirm" element={<CreateFirm />} />
                <Route path="/editFirm" element={<CreateFirm />} />
                <Route path="/firm/:id" element={<Firm />} />
                <Route path="/billing" element={<Membership />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/managecalendar" element={<ManageCalendar />} />
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/admin/user/:userId" element={<AdminUserUpdate />} />
                <Route path="/matters/:id" element={<ConsumerMatterDetails />} />
                <Route path="/matters/booking/:id" element={<BookingDetails />} />
                <Route path="/inquiries/:id" element={<InquiryDetails />} />
                <Route path="/bookings/:id" element={<BookingDetailsDirectLink />} />
                <Route path="/bookings/cancel/:id" element={<BookingCancel />} />
                <Route path="/bookings/reschedule/:id" element={<BookingReschedule />} />
              </Route>
              <Route path="/searchresult" element={<SearchResults />} />
              <Route path="/profile/:id" element={<ProviderDetailsNewScope /> } />
              <Route path="/lawyer/:name/(.html)?" element={<ProviderDetailsNewScope />} />
              <Route path="/" element={<Home />} />
              <Route path="/index.html" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/providerdetails" element={<ProviderDetails />} />
              <Route
                path="/premiumproviderdetails"
                element={<ProviderDetails isPremiumEnabled={true} />}
              />
              <Route path="/businessdetailmui" element={<BusinessDetail />} />
              <Route
                path="/individualconsumerprofile"
                element={<IndividualConsumerProfile />}
              />
              <Route path="/juricon" element={<Juricon />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
            {isJuriconRoute ? null : <Footer />}
          </div>
          <MountModals />
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
};

export default App;
