import React, { useEffect } from "react";
import Cards from "../components/Cards";
import HelpBanner from "../components/HelpBanner";
import GeneralSearch from "components/search/Search";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import WhatsNewCard from "components/cards/WhatsNewCard";
import { isCalAtomsUserIDValid } from "utils/config";
import { useAxios } from "utils/hooks/useAxios";

const Home = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home page",
    });
  }, []);

  // check if the user came from a different page
  let params = new URLSearchParams(location.search);
  let from = params.get("from") || "/";

  // check to see if the user is logged in
  const user = useSelector((state) => state.user);
  const userType = useSelector((state) => state.user?.userType);
  const { isLoading, callApi } = useAxios();
  const dispatch = useDispatch();

  if (user != null && from !== "/") {
    return <Navigate to={from} />;
  }
  else {
    return (
      <div>
        <GeneralSearch />
        { userType == "PROVIDER" && isCalAtomsUserIDValid(user?.calAtomsUserId) && !!user?.subscription && (
          <>
          <div className="flex justify-center items-center p-4 md:p-4">
            <WhatsNewCard />
          </div>
          </>
        )}
        { userType !== "PROVIDER" && (
          <>
          <Cards />
          </>
        )}
        <div className="flex justify-center items-center p-4 md:p-4">
          <HelpBanner />
        </div>
      </div>
    );
  }
};

export default Home;
