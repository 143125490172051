/* eslint-disable prettier/prettier */
import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/logo_white.png";
import CopyRight from "./CopyRight";

const footerMenu = [
  { name: "About", url: "about", externalLink: false },
  { name: "News", url: "https://news.juricon.ai", externalLink: true },
  // "Careers",
  { name: "Contact", url: "mailto:info@juricon.ai", externalLink: false },
  {
    name: "Terms of Service",
    url: "https://app.termly.io/document/terms-of-service/2350bad3-30e8-43be-82df-bc35e0b06bd6",
    externalLink: true,
  },
  {
    name: "Privacy Policy",
    url: "https://app.termly.io/document/privacy-policy/79ea0a88-7cf7-4d24-939a-2351f98ce44f",
    externalLink: true,
  },
  // { name: "Help Center", url: "#", externalLink: false },
];
const Footer = () => {
  return (
    <div className="mt-auto">
      <div className="bg-secondary text-white p-5 flex flex-col items-center ">
        <img src={Logo} alt="Logo" className="pt-[3rem]" />
        <ul
          className="flex justify-evenly flex-wrap
       mt-5"
        >
          {footerMenu.map((item) => {
            if (item?.externalLink) {
              return (
                <a
                  href={item.url}
                  key={item.name}
                  className="text-[1rem] ml-[22px] mb-3 footerl hover:opacity-70 "
                >
                  {item.name}
                </a>
              );
            }
            return (
              <NavLink
                to={item.url}
                className="text-[1rem] ml-[22px] mb-3 footerl hover:opacity-70 "
                key={item.name}
              >
                {item.name}
              </NavLink>
            );
          })}
        </ul>
      </div>
      <CopyRight />
    </div>
  );
};

export default Footer;
