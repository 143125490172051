import React from "react";
import { NavLink } from "react-router-dom";
// import instagram from "../assets/instagram.svg";
// import twitter from "../assets/twitter.svg";
// import youtube from "../assets/youtube.svg";

const CopyRight = () => {
  return (
    <footer className="bg-secondary text-white p-5 text-center">
      <div className="w-[90%] m-auto flex flex-col sm:flex-row justify-between">
        <h3>© 2022 JuriCon.ai All rights reserved.</h3>
        {/* <div className="flex justify-center space-x-5 -order-1 sm:order-1 mb-4">
          <NavLink>
            <img
              className="bg-[#fafafa1e] hover:bg-primary px-[11px] py-[11px] rounded-[60px]"
              src={instagram}
              alt=""
            />
            <div className="flex justify-center text-sm w-[30px] h-[30px] bg-[#fafafa1e] hover:bg-primary rounded-[60px]">
              <i
                className="fa-brands fa-facebook-f  self-center
              "
              />
            </div>
          </NavLink>
          <NavLink>
            <img
              className="bg-[#fafafa1e] hover:bg-primary px-2 py-2 pt-[9px] w-[35px] rounded-[60px]"
              src={twitter}
              alt=""
            />
            <div className="flex justify-center text-sm w-[30px] h-[30px] bg-[#fafafa1e] hover:bg-primary rounded-[60px]">
              <i
                className="fa-brands fa-twitter  self-center
              "
              />
            </div>
          </NavLink>
          <NavLink>
            {" "}
            <img
              className="bg-[#fafafa1e] hover:bg-primary px-2 py-[10px] pt-[9px] w-[37px] rounded-[60px]"
              src={youtube}
              alt=""
            />
            <div className="flex justify-center text-sm w-[30px] h-[30px] bg-[#fafafa1e] hover:bg-primary rounded-[60px]">
              <i
                className="fa-brands fa-youtube  self-center
              "
              />
            </div>
          </NavLink>
        </div> */}
      </div>
    </footer>
  );
};

export default CopyRight;
