import { useSelector } from "react-redux";
import "@calcom/atoms/globals.min.css";
import { CalProvider } from '@calcom/atoms';
import { AvailabilitySettings } from "@calcom/atoms";
import { useNavigate } from "react-router-dom";
import {
  CAL_ATOMS_CLIENT_ID,
  CAL_ATOMS_API_URL,
  CAL_ATOMS_REFRESH_URL,
} from "utils/constants/constants";
import { notify } from "utils/toast/toast";
import { Stack } from "@mui/material";
import arrow from "components/PremiumProviderEditProfileComponents/providermui/imgs/arow.svg";

const ManageCalendar = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const calAtomsAccessToken = user?.calAtomsAccessToken ?? "";

  return (
    <CalProvider
      accessToken={calAtomsAccessToken}
      clientId={CAL_ATOMS_CLIENT_ID}
      options={{
        apiUrl: CAL_ATOMS_API_URL,
        refreshUrl: CAL_ATOMS_REFRESH_URL 
      }}
      >
      <div className="px-10">
        <button
          className="bg-transparent hover:bg-blue-gray-100 sm:h-[40px] rounded-xs text-blue-gray-900 duration-300"
          onClick={() => navigate("/")}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            padding="5px"
            spacing="2px"
            mr="3px"
          >
            <img
              className="w-[15px] h-[15px] rounded-[2px] rotate-180"
              src={arrow}
              alt=""
            />
              <p>{"Back to Home"}</p>
          </Stack>
        </button>
        <AvailabilitySettings
          customClassNames={{
            subtitlesClassName: "text-green-500",
            ctaClassName: "border p-4 rounded-md",
            editableHeadingClassName: "underline font-semibold",
          }}
          onUpdateSuccess={(data) => {
            console.log("Availability Updated successfully = ", data);
          }}
          onUpdateError={() => {
            console.log("update error");
          }}
          onDeleteSuccess={() => {
            console.log("Deleted successfully");
          }}
          onDeleteError={() => {
            console.log("delete error");
          }}
        />
      </div>
    </CalProvider>
  );
};

export default ManageCalendar;
