import React, { useEffect, useLayoutEffect, useState } from "react";
import MyTable from "components/Dashboard/table/Table";
import { Box, Pagination, Stack, Tab, Tabs } from "@mui/material";
import BreadCrumb from "components/Dashboard/Bredcrums/BreadCrum";
import RenderExtended from "components/Dashboard/table//RenderExtended";
import {
  bookmarkedColumns,
  consumerTabs,
  myInquiriesColumns,
  myMattersColumns,
  providerInquiryColumns,
  providerMatterColumns,
  providerTabs,
  recentColumns,
} from "components/Dashboard/table/helper";
import { useDispatch, useSelector } from "react-redux";
import CollapsibleSearchInput from "components/SearchInput";
import { useAxios } from "utils/hooks/useAxios";
import { getRecentSearches } from "services/getRecentSearches";
import { calculateTotalPages } from "components/Dashboard/helper";
import { isUserVerified } from "utils/config";
import ProgressSummary from "components/Dashboard/progressSummary/ProgressSummary";
import { fetchProviderStats } from "services/fetchProviderStats";
import { initializeDateFilter } from "store/actions/actions";
import { fetchMatters } from "services/fetchMatters";
import { notify } from "utils/toast/toast";
import { useNavigate, useLocation } from "react-router-dom";
import SortPopover from "components/popover/SortPopover";
import { fetchMyInquiries } from "services/fetchMyInquiries";

const DashBoard = () => {
  const pageLimit = 10;
  const userType = useSelector((state) => state.user.userType);
  const [mouseOverApisData, setMouseOverApisData] = useState({
    bookmarked: false,
    myMatters: false,
    inquiry: false,
  });
  const user = useSelector((state) => state.user);
  const { startDate, endDate } = useSelector((state) => state.dateReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("-createdAt");
  const [searchesList, setSearchesList] = useState([]);
  const [bookmarkedList, setBookmarkedList] = useState([]);
  const [mattersList, setMattersList] = useState([]);
  const [inquiriesList, setInquiriesList] = useState([]);
  const [providerStats, setProviderStats] = useState({});
  const tabValue = parseInt(new URLSearchParams(location.search).get('value') ?? 0)
  const [value, setValue] = useState(tabValue);
  const [currentSearchPage, setCurrentSearchPage] = useState(0);
  const [currentBookmarkedPage, setCurrentBookmarkedPage] = useState(1);
  const [currentMattersPage, setCurrentMattersPage] = useState(1);
  const [currentInquiriesPage, setCurrentInquiriesPage] = useState(1);
  const [totalSearchRecords, setTotalSearchRecords] = useState(pageLimit);
  const [totalBookmarkedRecords, setTotalBookmarkedRecords] =
    useState(pageLimit);
  const [totalMattersRecords, setTotalMattersRecords] = useState(pageLimit);
  const [totalInquiries, setTotalInquiries] = useState(pageLimit);
  const [isLoading, setIsLoading] = useState(true);
  const { callApi } = useAxios();
  const { isLoading: mattersLoading, callApi: mattersApi } = useAxios();
  const { isLoading: inquiriesLoading, callApi: inquiriesApi } = useAxios();
  const { isLoading: isBookmarkedLoading, callApi: bookMarkedSearchesApi } =
    useAxios();
  const userVerified = isUserVerified(user);

  useLayoutEffect(() => {
    const userHaveNotSubscription = !user?.subscription && user?.userType === "PROVIDER";
    if (!userVerified?.status) {
      if (user.stepsCompleted < 6 || !user.stepsCompleted) { // user has not completed their profile
        notify(userVerified.message, "warning");
        navigate("/completeprofile", {state: {isProfileComplete: false}});
      } else if (userHaveNotSubscription) { // user has not subscribed
        navigate("/profile");
      } else { // user has subscribed and their profile is under review
        notify(userVerified.message, "warning");
        navigate("/completeprofile", {state: {isProfileComplete: true}});
      }
    }
  }, []);
  useEffect(() => {
    if (searchText) {
      handlePageChange(1);
    }
  }, [searchText]);
  useEffect(() => {
    handlePageChange(1);
  }, [sortBy]);
  useEffect(() => {
    if (
      userType !== "PROVIDER" &&
      !searchesList.length &&
      currentSearchPage > 1
    ) {
      handlePageChange(currentSearchPage - 1);
    } else if (
      userType !== "PROVIDER" &&
      !searchesList.length &&
      currentSearchPage === 1
    ) {
      handlePageChange(1);
    }
  }, [searchesList]);

  useEffect(() => {
    //reset the date filters of provider at mounting
    if (userVerified.status && userType === "PROVIDER") {
      dispatch(initializeDateFilter());
    }
    // fetchMatters(
    //   mattersApi,
    //   { page: currentMattersPage, limit: pageLimit },
    //   (data) => {
    //     setTotalMattersRecords(data?.totalRecords);
    //     setMattersList(data?.appointments);
    //     totalPages();
    //   }
    // );
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setIsLoading(true);
      fetchProviderStats(
        callApi,
        { fromDate: startDate, toDate: endDate },
        (data) => {
          setIsLoading(false);
          setProviderStats(data.data);
        }
      );
    } else if (Object.keys(providerStats).length) {
      setIsLoading(true);
      fetchProviderStats(
        callApi,
        { fromDate: startDate, toDate: endDate },
        (data) => {
          setIsLoading(false);
          setProviderStats(data.data);
        }
      );
    }
  }, [startDate, endDate]);

  const handlePageChange = (pageNumber) => {
    const params = { page: pageNumber, limit: pageLimit, sort: sortBy };
    if (searchText) {
      if (value === 3 || (user?.userType === "PROVIDER" && value === 1)) {
        params.searchText = searchText;
      } else {
        params.search = searchText;
      }
    }
    if (user.userType === "PROVIDER") {
      if (value === 0) {
        setCurrentInquiriesPage(pageNumber);
        fetchMyInquiries(inquiriesApi, params, (data) => {
          setTotalInquiries(data?.total);
          setInquiriesList(data?.inquiries);
          totalPages();
        });
      } else if (value === 1) {
        setCurrentMattersPage(pageNumber);
        fetchMatters(mattersApi, params, (data) => {
          setTotalMattersRecords(data?.totalRecords);
          setMattersList(data?.appointments);
          totalPages();
        });
      }
    } else {
      if (value === 0) {
        setIsLoading(true);
        setCurrentSearchPage(pageNumber);
        getRecentSearches(callApi, params, (data) => {
          setIsLoading(false);
          if (data) {
            setTotalSearchRecords(data?.totalRecords);
            data?.mySearches?.length && setSearchesList(data?.mySearches);
            totalPages();
          }
        });
      } else if (value === 1) {
        setCurrentBookmarkedPage(pageNumber);
        getRecentSearches(
          bookMarkedSearchesApi,
          params,
          (data) => {
            setTotalBookmarkedRecords(data?.totalRecords);
            setBookmarkedList(data?.mySearches);
            totalPages();
          },
          true
        );
      } else if (value === 2) {
        setCurrentMattersPage(pageNumber);
        fetchMatters(mattersApi, params, (data) => {
          setTotalMattersRecords(data?.totalRecords);
          setMattersList(data?.appointments);
          totalPages();
        });
      } else if (value === 3) {
        setCurrentInquiriesPage(pageNumber);
        fetchMyInquiries(inquiriesApi, params, (data) => {
          setTotalInquiries(data?.total);
          setInquiriesList(data?.inquiries);
          totalPages();
        });
      }
    }
  };

  useEffect(() => {
    if (user.userType === "PROVIDER") {
      const columns =
        value === 0 ? providerInquiryColumns : providerMatterColumns;
      setColumns(columns);
    } else {
      const columns =
        value === 0
          ? recentColumns
          : value === 1
          ? bookmarkedColumns
          : value === 2
          ? myMattersColumns
          : myInquiriesColumns;
      setColumns(columns);
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchText("");
  };
  // created this function to fetch results of the tab on hover so when the use select the tab the result will be loaded
  const handleTabMouseOver = ({ target }) => {
    if (
      target?.textContent === "Bookmarked" &&
      !mouseOverApisData?.bookmarked
    ) {
      getRecentSearches(
        bookMarkedSearchesApi,
        { page: currentBookmarkedPage, limit: pageLimit, sort: sortBy },
        (data) => {
          if (data) {
            updateMouseOverApiState("bookmarked");
            setTotalBookmarkedRecords(data?.totalRecords);
            setBookmarkedList(data?.mySearches);
          }
        },
        true
      );
    } else if (
      target?.textContent === "My Bookings" &&
      !mouseOverApisData?.myMatters
    ) {
      fetchMatters(
        mattersApi,
        { page: currentMattersPage, limit: pageLimit, sort: sortBy },
        (data) => {
          updateMouseOverApiState("myMatters");
          setTotalMattersRecords(data?.totalRecords);
          setMattersList(data?.appointments);
          totalPages();
        }
      );
    } else if (
      target?.textContent === "My Inquiries" &&
      !mouseOverApisData?.inquiry
    ) {
      fetchMyInquiries(
        inquiriesApi,
        { page: currentMattersPage, limit: pageLimit, sort: sortBy },
        (data) => {
          updateMouseOverApiState("inquiry");
          setTotalInquiries(data?.total);
          setInquiriesList(data?.inquiries);
          totalPages();
        }
      );
    }
  };

  const updateMouseOverApiState = (key) => {
    setMouseOverApisData((pre) => ({
      ...pre,
      [key]: true,
    }));
  };

  const handleBookmarkClick = (searchId, providerId) => {
    setBookmarkedList((pre) => {
      pre.map((search) => {
        if (search._id === searchId) {
          const tempLawyers = search.bookmarkedLawyers.filter(
            (lawyer) => lawyer._id !== providerId
          );
          search.bookmarkedLawyers = tempLawyers;
        }
        return search;
      });
      return [...pre];
    });
  };

  const totalPages = () => {
    if (user.userType === "PROVIDER") {
      return calculateTotalPages(
        value === 0 ? totalInquiries : totalMattersRecords,
        pageLimit
      );
    }
    return calculateTotalPages(
      value === 0
        ? totalSearchRecords
        : value === 1
        ? totalBookmarkedRecords
        : value === 2
        ? totalMattersRecords
        : totalInquiries,
      pageLimit
    );
  };
  const currentPage = () => {
    if (user?.userType === "PROVIDER") {
      return value === 0 ? currentInquiriesPage : currentMattersPage;
    }
    return value === 0
      ? currentSearchPage
      : value === 1
      ? currentBookmarkedPage
      : value === 2
      ? currentMattersPage
      : currentInquiriesPage;
  };
  return (
    <Box sx={{ pt: "50px", width: "94%", margin: "0 auto" }}>
      <BreadCrumb label="My Dashboard" />
      {userType === "PROVIDER" && (
        <ProgressSummary
          ProviderStats={providerStats}
          isLoading={isLoading}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <Box sx={{ width: "100%" }}>
        <Stack
          mb={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={value}
            onMouseOver={handleTabMouseOver}
            onChange={handleChange}
            variant="scrollable"
          >
            {(userType !== "PROVIDER" ? consumerTabs : providerTabs).map(
              (tab) => (
                <Tab
                  key={tab}
                  label={tab}
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    textTransform: "none",
                    "&.MuiTab-root": {
                      color: "#C6C7C4",
                      padding: "1rem 0 .5rem 0",
                      mr: 3,
                      textTransform: "none",
                    },
                    "&.Mui-selected": {
                      color: "#00A4DA",
                    },
                  }}
                />
              )
            )}
          </Tabs>
          {
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CollapsibleSearchInput
                searchText={searchText}
                setSearchText={setSearchText}
              />
              <SortPopover state={sortBy} setState={setSortBy} />
            </Stack>
          }
        </Stack>
        {userType === "PROVIDER" ? (
          <Box
            sx={{
              overflow: "scroll ",
            }}
          >
            <MyTable
              isLoading={value === 0 ? inquiriesLoading : mattersLoading}
              columns={columns}
              rows={value === 0 ? inquiriesList : mattersList}
              setLists={setSearchesList}
            />
          </Box>
        ) : (
          <Box
            sx={{
              overflow: "scroll ",
            }}
          >
            <MyTable
              isLoading={
                value === 0
                  ? isLoading
                  : value === 1
                  ? isBookmarkedLoading
                  : value === 2
                  ? mattersLoading
                  : inquiriesLoading
              }
              columns={columns}
              rows={
                value === 0
                  ? searchesList
                  : value === 1
                  ? bookmarkedList
                  : value === 2
                  ? mattersList
                  : inquiriesList
              }
              setLists={
                value === 0
                  ? setSearchesList
                  : value === 1
                  ? setBookmarkedList
                  : value === 2
                  ? setMattersList
                  : setInquiriesList
              }
              extendedTemplate={
                value === 1
                  ? (props) => (
                      <RenderExtended
                        handleBookmarkClick={handleBookmarkClick}
                        props={props}
                        open={true}
                        isLoading={isLoading}
                      />
                    )
                  : null
              }
            />
          </Box>
        )}
      </Box>
      <Stack direction="row" my={4} justifyContent="space-between">
        <Box />
        {totalPages() > 1 && (
          <Pagination
            page={currentPage()}
            count={totalPages()}
            onChange={(_, pageNumber) => handlePageChange(pageNumber)}
          />
        )}
      </Stack>
    </Box>
  );
};

export default DashBoard;
